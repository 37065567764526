<template>
  <!-- 计费方案 - 月租卡计费方案 -->
  <div class="box-card" style="text-align: left;">
    <!-- 主要内容 -->
    <div>
      <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px" class="demo-ruleForm">
        <el-form-item label="方案名称：" prop="scheme_name">
          <el-input v-model="addForm.scheme_name" maxlength="20" @input="(e) => (addForm.scheme_name = validSpace(e))"
            placeholder="请输入方案名称"></el-input>
        </el-form-item>
        <el-form-item label="方案详情：" prop="scheme_details">
          <el-input type="textarea" maxlength="300" v-model="addForm.scheme_details" :rows="5" placeholder="请输入方案详情"
            @blur="addForm.scheme_details = ((addForm.scheme_details.replace(/\r\n|\n|\r/g, '<br/>')).replace(/\s/g, '')).replace(/<br\/>/g, '\n')"></el-input>
        </el-form-item>
        <el-form-item label="收费：" prop="amount">
          <el-input v-model="addForm.amount" maxlength="9" @input="(e) => (addForm.amount = validSpacing(e))"
            placeholder="请输入收费金额" style="margin-right:15px;"></el-input>元
        </el-form-item>
        <el-form-item label="时段规则：" prop="time_rule_type">
          <el-select popper-class="my-select" v-model="addForm.time_rule_type" placeholder="请选择时段规则" style="width: 100%;">
            <el-option label="无" :value="1"></el-option>
            <el-option label="自定义时段" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <div v-if="addForm.time_rule_type == 2">
          <el-form-item label="开始时段：" prop="begin_time">
            <!-- <el-time-select placeholder="请选择开始时间" v-model="addForm.begin_time" value-format="HH:mm:ss" style="width: 100%;" :picker-options="{start: '00:00:00',step: '00:30:00', end: '23:59:59' }">
                  </el-time-select> -->
            <el-time-picker placeholder="请选择开始时间" :default-value="beginTimeShow" format="HH:mm" value-format="HH:mm"
              :editable="false" v-model="addForm.begin_time" style="width: 100%;">
            </el-time-picker>
          </el-form-item>
          <el-form-item label="结束时段：" prop="end_time">
            <el-time-picker placeholder="请选择结束时间" :default-value="endTimeShow" format="HH:mm" value-format="HH:mm"
              :editable="false" v-model="addForm.end_time" style="width: 100%;"></el-time-picker>
          </el-form-item>
          <el-form-item label="段外收费：" prop="cost_scheme_id">
            <el-select popper-class="my-select" v-model="addForm.cost_scheme_id" placeholder="请选择段外收费方案"
              style="width: 100%;">
              <el-option v-for="(item, index) in timeCountSelect" :key="index" :label="item.scheme_name"
                :value="item._id"></el-option>
              <!-- <el-option label="区域二" value="beijing"></el-option> -->
            </el-select>
          </el-form-item>
        </div>
        <!-- <lbButton type="goBack" icon="back" class="checkButton2" @click="defaultForm()" v-preventReClick>返 回</lbButton> -->
        <lbButton type="default" fill icon="confirm" class="checkButton" @click="submitAddForm()" v-preventReClick>保 存</lbButton>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getMonthCardView, getMonthlyCardList, delMonthCard, addMonthCard, editMonthCard, getTimeCountSelectList } from '@/api/billingProject'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
export default {
  props: { month_id: { type: String } },
  data () {
    return {
      beginTimeShow: new Date('2021-5-26 00:00:00'),
      endTimeShow: new Date('2021-5-26 23:59:59'),
      // 查询表单
      title: '',
      // 新增/编辑停车场表单
      addForm: {
        _id: '',
        scheme_name: '',
        scheme_details: '',
        amount: '',
        begin_time: '',
        end_time: '',
        cost_scheme_id: '',
        // 时段规则 1:无 ，2:自定义时段
        time_rule_type: 1
      },
      // 新增/编辑停车场表单验证
      addFormRules: {
        scheme_name: [
          { required: true, message: '请输入方案名称', trigger: 'blur' }
        ],
        amount: [
          { required: true, message: '请输入收费金额', trigger: 'blur' },
          { pattern: /^([1-9]\d*|0)(\.\d{1,2})?$/, message: '请输入带两位小数的数字', trigger: 'blur' }
        ],
        scheme_details: [
          { required: true, message: '请输入方案详情', trigger: 'blur' }
        ],
        begin_time: [
          { required: true, message: '请选择开始时段', trigger: 'blur' }
        ],
        end_time: [
          { required: true, message: '请选择结束时段', trigger: 'blur' }
        ],
        cost_scheme_id: [
          { required: true, message: '请选择段外收费', trigger: 'blur' }
        ],
        time_rule_type: [
          { required: true, message: '请选择时段规则', trigger: 'blur' }
        ]
      },
      // 计时计次方案下拉列
      timeCountSelect: []
    }
  },
  created () {
    this.fngetTimeCountSelectList()
    if (this.month_id) {
      this.title = '编辑月租卡计费方案'
      this.fngetMonthCardView()
    } else {
      this.title = '新增月租卡计费方案'
    }
  },
  mounted () {
  },
  computed: {
    ...mapState(['parkingList']),
    ...mapState('menuList', ['isRoutW']),
    ...mapGetters(['getOptions', 'getWayName'])
  },
  methods: {
    ...mapActions(['requestPorkingList', 'requestFindList']),
    ...mapMutations(['setQueryStatus']),
    goBack () {
      this.setQueryStatus(2)
      this.$router.go(-1)
    },
    // 获取编辑月租卡信息界面
    async fngetMonthCardView () {
      const res = await getMonthCardView({
        month_id: this.month_id
      })
      if (res && res.Code === 200) {
        console.log(res.Data)
        this.addForm._id = res.Data._id
        this.addForm.scheme_name = res.Data.scheme_name
        this.addForm.scheme_details = res.Data.scheme_details
        this.addForm.amount = Number(res.Data.amount).toFixed(2)
        this.addForm.begin_time = this.$moment('2021-5-26 ' + res.Data.begin_time).format('HH:mm')
        this.addForm.end_time = this.$moment('2021-5-26 ' + res.Data.end_time).format('HH:mm')
        this.addForm.time_rule_type = res.Data.time_rule_type
        if (res.Data.time_rule_type !== 1) {
          this.addForm.cost_scheme_id = res.Data.cost_scheme_id
        }
      }
    },
    //  计时计次方案下拉列
    async fngetTimeCountSelectList () {
      const res = await getTimeCountSelectList()
      // console.log(res);
      this.timeCountSelect = res.Code === 200 ? res.Data : []
    },
    // 新增/编辑
    submitAddForm () {
      // this.isBatchAddDialogVisible = false
      // 对整个表单进行校验
      this.$refs.addFormRef.validate((valid) => {
        // 成功
        if (valid) {
          var obj = {}
          obj = {
            scheme_name: this.addForm.scheme_name,
            scheme_details: this.addForm.scheme_details,
            amount: Number(this.addForm.amount).toFixed(2),
            time_rule_type: this.addForm.time_rule_type
          }
          if (this.addForm.time_rule_type === 2) {
            obj.begin_time = this.addForm.begin_time + ':00'
            obj.end_time = this.addForm.end_time + ':59'
            obj.cost_scheme_id = this.addForm.cost_scheme_id
          }
          if (this.title === '新增月租卡计费方案') {
            // console.log(obj);
            this.fnaddMonthCard(obj)
          } else {
            obj._id = this.addForm._id
            // console.log(obj);
            this.fneditMonthCard(obj)
          }
        } else {
          return false
        }
      })
    },
    // 新增月租卡计费方案接口
    async fnaddMonthCard (obj) {
      const res = await addMonthCard(obj)
      // console.log(res);
      if (res && res.Code === 200) {
        // this.$router.go(-1)
        this.$emit('closeDialog', false)
      }
    },
    // 编辑月租卡计费方案
    async fneditMonthCard (obj) {
      const res = await editMonthCard(obj)
      // console.log(res);
      if (res && res.Code === 200) {
        this.$emit('closeDialog', false)
        // this.$router.go(-1);
        // this.goBack()
      }
    },
    defaultForm () {
      this.$emit('closeDialog', false)
    }
  }
}
</script>

<style scoped lang="less">
.demo-ruleForm {
  text-align: left;
  overflow: auto;

  /deep/ .el-date-editor,
  .el-textarea,
  .el-select,
  .el-input {
    width: 90% !important;
  }

  // .el-input {
  //     width: 450px;
  //     margin-right: 10px;
  // }
  // .quota_box {
  //     display: flex;
  // }
}

.box-card {
  position: relative;
  padding-bottom: 30px;

  .checkButton {
    position: absolute;
    right: 0px;
  }

  .checkButton2 {
    position: absolute;
    right: 100px;
  }
}</style>
